import React from 'react';
import { observer } from 'mobx-react';
import Scrollbar from '../Base/Scrollbar';
import { ChatListType } from '~/types/chat.types';

import Box from '@material-ui/core/Box';

import authStore from '~/stores/authStore';
import chatStore from '~/stores/chatStore';

import Progress from '../Base/ui/Progress';

import ChatSendArea from './ChatSendArea';
import { WhatsappChatMessagesList } from '~/components/Items/Contact/WhatsappBlocks/WAMessagesList';

import ChatMessagesList from './ChatMessagesList';
import ChatAIButton from './ChatAIButton';

type ChatMessagesProps = {
    item_id: number;
    chatType: ChatListType;
};

@observer
class ChatMessages extends React.Component<ChatMessagesProps, ChatMessagesProps> {
    chatContainerRef: HTMLElement;

    constructor(props: ChatMessagesProps) {
        super(props);
        chatStore.fetchItem(props.item_id, props.chatType);
        this.state = { ...props };
    }

    static getDerivedStateFromProps(nextProps: ChatMessagesProps, currentState: ChatMessagesProps) {
        if (nextProps.item_id !== currentState.item_id) {
            chatStore.fetchItem(nextProps.item_id, nextProps.chatType, true);
        }
        return { ...nextProps };
    }

    componentDidUpdate() {
        this.chatContainerRef.scrollTop = 1000000;
    }

    //
    // http://localhost:3000/chat/whatsapp/1196/0

    render() {
        const { currentUser } = authStore;
        const { item_id, chatType } = this.props;
        const { loadingItem, item } = chatStore.getItem(item_id);
        const { user_id } = chatStore.listFilter;

        return (
            <div className="crm-Chat__mainContent">
                <div className="crm-Chat__mainContent_messages">
                    <Progress show={loadingItem} />

                    <Scrollbar suppressScrollX containerRef={$ref => (this.chatContainerRef = $ref)}>
                        {item?.chatType === 'users' && item.messages && (
                            <ChatMessagesList user_id={user_id} messages={[...item.messages]} chatType={item.chatType} />
                        )}
                        {item?.chatType === 'avito' && item.messages && (
                            <ChatMessagesList user_id={user_id} messages={[...item.messages]} chatType={item.chatType} />
                        )}
                        {item?.chatType === 'cian' && item.messages && (
                            <ChatMessagesList user_id={user_id} messages={[...item.messages]} chatType={item.chatType} />
                        )}
                        {item?.chatType === 'whatsapp' && (
                            <WhatsappChatMessagesList
                                messages={item.messages}
                                userAvatarUrl={currentUser.avatarUrl}
                                waContactAvatarUrl={item.waContact.photoUrl}
                            />
                        )}
                    </Scrollbar>
                    {item?.chatType === 'whatsapp' && (
                        <Box position="absolute" bottom={'1rem'} right={'1rem'}>
                            <ChatAIButton item_id={item_id} />
                        </Box>
                    )}
                </div>

                {chatStore.listFilter.user_id === currentUser.user_id && <ChatSendArea item_id={item_id} chatType={chatType} />}
            </div>
        );
    }
}

export default ChatMessages;
